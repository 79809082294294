import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Toptitle from "../components/toptitle"
import styled from "styled-components"

const Company = () => (
  <Layout>
    <SEO title="会社概要" />
    <div>
      <section className="w-full lg:mr-0 lg:ml-auto lg:w-2/3">
        <Toptitle>Company</Toptitle>
        <div className="pt-32 lg:mt-16 lg:pt-0">
          <h1 className="font-mincho text-3xl text-primary">会社概要</h1>
        </div>
        <table className="my-auto mt-8 w-full table-auto lg:w-4/5">
          <tbody>
            <tr className="border-y border-primarySupport">
              <td className="py-8 px-2 font-mincho">社名</td>
              <td className="pl-8 font-mincho">有限会社アートマテリアル</td>
            </tr>
            <tr className="border-y border-primarySupport">
              <td className="py-8 px-2 font-mincho">代表取締役</td>
              <td className="pl-8 font-mincho">坪倉 斉</td>
            </tr>
            <tr className="border-y border-primarySupport">
              <td className="py-8 px-2 font-mincho">資本金</td>
              <td className="pl-8 font-mincho">900万円</td>
            </tr>
            <tr className="border-y border-primarySupport">
              <td className="py-8 px-2 font-mincho">設立</td>
              <td className="pl-8 font-mincho">2005年 5月</td>
            </tr>
            <tr className="border-y border-primarySupport">
              <td className="py-8 px-2 font-mincho">所在地</td>
              <td className="pl-8 font-mincho">
                〒169-0072
                <br />
                東京都 新宿区 大久保 1-1-49 タキカワ大久保ビル9F
              </td>
            </tr>
            <tr className="border-y border-primarySupport">
              <td className="py-8 px-2 font-mincho">TEL/FAX</td>
              <td className="pl-8 font-mincho">03-3200-8883 / 03-3200-8778</td>
            </tr>
            <tr className="border-y border-primarySupport">
              <td className="py-8 px-2 font-mincho">工場</td>
              <td className="pl-8 font-mincho">
                〒470-0535
                <br />
                愛知県 豊田市 百月町 井戸洞 58-1
              </td>
            </tr>
            <tr className="border-y border-primarySupport">
              <td className="py-8 px-2 font-mincho">主要金融機関</td>
              <td className="pl-8 font-mincho">
                みずほ銀行 / りそな銀行 / 興産信用金庫
              </td>
            </tr>
            <tr className="border-y border-primarySupport">
              <td className="py-8 px-2 font-mincho">主要取引先</td>
              <td className="pl-8 font-mincho">
                ゼネコン / 百貨店 / 内装業者 / 建材商社
              </td>
            </tr>
            <tr className="border-y border-primarySupport">
              <td className="py-8 px-2 font-mincho">事業内容</td>
              <td className="pl-8 font-mincho">
                内装用不燃人工木材の加工・販売
              </td>
            </tr>
          </tbody>
        </table>
        <div className="mt-32">
          <h2 className="font-mincho text-3xl text-primary">アクセス</h2>
        </div>
        <Map className="w-full lg:w-4/5">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3240.1379533694067!2d139.70510845175838!3d35.69822268009303!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188cdf469b156f%3A0x9bb6c8e1e701270c!2z44CSMTY5LTAwNzIg5p2x5Lqs6YO95paw5a6_5Yy65aSn5LmF5L-d77yR5LiB55uu77yR4oiS77yU77yZIOOCv-OCreOCq-ODr-Wkp-S5heS_neODk-ODqyA5Zg!5e0!3m2!1sja!2sjp!4v1650422351403!5m2!1sja!2sjp"
            allowFullScreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
            className="h-full w-full"
          ></iframe>
        </Map>
      </section>
    </div>
  </Layout>
)

const Map = styled.div`
  height: 600px;
  margin-top: 48px;
`

export default Company
